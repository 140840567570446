import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBasicParams, ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import {
  CreateUserGroups,
  UserGroups,
  UserGroupsDetails
} from '@core/models/interfaces/user-group';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService {
  private baseUrl = `${environment.API_URL}/crm/group-viewers`;

  constructor(private httpClient: HttpClient) {}

  getGroupById(id: string) {
    return this.httpClient.get<ResponseApi<UserGroupsDetails>>(`${this.baseUrl}/${id}`);
  }

  updateGroupById(body: CreateUserGroups, id: string) {
    return this.httpClient.put<ResponseApi<UserGroupsDetails>>(`${this.baseUrl}/${id}`, body);
  }

  deleteGroupById(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }

  updateViewerForGroup(
    id: string,
    body: {
      viewerAddIds: string[];
      viewerDeleteIds: string[];
    }
  ) {
    return this.httpClient.put(`${this.baseUrl}/${id}/viewers`, body);
  }

  updateMembersForGroup(
    id: string,
    body: {
      memberAddIds: string[];
      memberDeleteIds: string[];
    }
  ) {
    return this.httpClient.put(`${this.baseUrl}/${id}/members`, body);
  }

  getGroups(params?: ApiBasicParams) {
    let httpParams = new HttpParams();
    if (params?.keyword) {
      httpParams = httpParams.append('keyword', params?.keyword);
    }
    if (params?.page) {
      httpParams = httpParams.append('page', params?.page);
    }
    if (params?.size) {
      httpParams = httpParams.append('size', params?.size);
    }
    if (params?.sorts) {
      httpParams = httpParams.append('sorts', params?.sorts);
    }

    return this.httpClient.get<ResponseListApi<UserGroups>>(`${this.baseUrl}`, {
      params: httpParams
    });
  }

  getGroupsPermission(params?: ApiBasicParams) {
    let httpParams = new HttpParams();
    if (params?.keyword) {
      httpParams = httpParams.append('keyword', params?.keyword);
    }
    if (params?.page) {
      httpParams = httpParams.append('page', params?.page);
    }
    if (params?.size) {
      httpParams = httpParams.append('size', params?.size);
    }
    if (params?.sorts) {
      httpParams = httpParams.append('sorts', params?.sorts);
    }

    return this.httpClient.get<ResponseListApi<UserGroups>>(`${this.baseUrl}/groups-permission`, {
      params: httpParams
    });
  }

  createGroup(body: CreateUserGroups) {
    return this.httpClient.post<ResponseApi<UserGroupsDetails>>(`${this.baseUrl}`, body);
  }

  cloneGroupById(id: string) {
    return this.httpClient.post<ResponseApi<UserGroupsDetails>>(`${this.baseUrl}/clone/${id}`, id);
  }
}
